import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Section from "../components/Section"
import BlogCard from "../components/BlogCard"

import Filters from "../components/Filters"
import opengraph from "../images/opengraph.png"

/* import Filters from "../components/Filters"
 */
const BlogContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
`

const BlogPage = ({ data }) => (
  <Layout pageTitle="Blog" image={opengraph}>
    <Filters currentTag="all" tags={data.tags.group} />
    <Section>
      <BlogContainer>
        {data.posts.nodes.map(post => (
          <BlogCard key={post.id} to={post.path.alias} post={post} />
        ))}
      </BlogContainer>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    posts: allNodeBlogs(sort: { fields: created, order: DESC }) {
      nodes {
        title
        id
        path {
          alias
        }
        field_description
        relationships {
          field_blog_image {
            drupal_id
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5, width: 600)
              }
            }
          }
          field_category {
            name
          }
        }
      }
    }
    tags: allNodeBlogs {
      group(field: relationships___field_category___name) {
        fieldValue
        totalCount
      }
    }
  }
`

export default BlogPage
